/**
 * Defines different values for the sorting direction of the `<dx-table>`.
 */
var SortDirection;
(function (SortDirection) {
  /**
   * Entries are sorted in an ascending order for a defined column.
   */
  SortDirection["ASC"] = "asc";
  /**
   * Entries are sorted in a desceding order for a defined column.
   */
  SortDirection["DESC"] = "desc";
  /**
   * Entries are not sorted for a defined column.
   */
  SortDirection["NEUTRAL"] = "neutral";
})(SortDirection || (SortDirection = {}));
/**
 * The type of a `<dx-table-header-item>` element.
 */
var ColumnType;
(function (ColumnType) {
  /**
   * HTML-Code can be slotted in all cells defined within the corresponding column.
   */
  ColumnType["HTML"] = "html";
  /**
   * All cell `value` properties will be formatted as text.
   */
  ColumnType["TEXT"] = "text";
  /**
   * All cell `value` properties will be formatted as numbers which are right-aligned by default.
   */
  ColumnType["NUMERIC"] = "numeric";
  /**
   * All cell `value` properties will be formatted as dates which are right-aligned by default.
   */
  ColumnType["DATE"] = "date";
  /**
   * All cell `value` properties will be formatted as currencies, with precision of two (e.g. "42,00"), which are right-aligned by default.
   */
  ColumnType["CURRENCY"] = "currency";
})(ColumnType || (ColumnType = {}));
/**
 * Contains values for defining the sorting mode of a `<dx-table>` element.
 */
var SortControlPosition;
(function (SortControlPosition) {
  /**
   * Only sort-buttons will be displayed next to the label of sortable `<dx-table-header-item>` elements
   */
  SortControlPosition["LABEL"] = "label";
  /**
   * A dropdown and a sort button are displayed on the top of a `<dx-table>` element. The dropdown contains an entry for every sortable `<dx-table-header-item>` element. The button controls the `SortDirection`.
   */
  SortControlPosition["OUTSOURCED"] = "outsourced";
  /**
   * A combination of LABEL and OUTSOURCED.
   */
  SortControlPosition["COMBINED"] = "combined";
})(SortControlPosition || (SortControlPosition = {}));
export { ColumnType as C, SortDirection as S, SortControlPosition as a };